import React from 'react'
import loc from './contact1/icn settings .icn-xl.png'
import contacthero from './homepage/contact5.jpg'
// import shanghai from './homepage/shanghai.jpeg'
// import shanghai2 from './homepage/shanghai.jpg'
// import shanghai3 from './homepage/shanghai3.jpg'
import { Trans, useTranslation } from 'react-i18next'

function Contactus() {
  const { t, i18n } = useTranslation()

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }
  return (
    <div className="investment">
      <div className="contacthero">
        {' '}
        <img src={contacthero} alt="hero-contact" />{' '}
      </div>
      <div className="investmain h3 font-weight-bold">
        <Trans i18nKey="description.part84">Get In Touch</Trans>
      </div>
      <div className="contactcards">
        <div className="contactcard1">
          <img className="pb-2" src={loc} alt="" />
          <div className="font-weight-bold pb-3">
            <Trans i18nKey="description.part85">Lantern Capital Partners</Trans>
          </div>
          <div>

            <Trans i18nKey="description.part86">
              Lee Farm Corporate Park <br />
              83 Wooster Heights Road, Suite 125 <br />
              Danbury, CT 06810 <br />
              +1 (914) 552-5544 <br />
            </Trans>
          </div>

          <br />
          <a className="btncon5" href="tel:+19145525544">
            <Trans i18nKey="description.part115">Call us</Trans>
          </a>
        </div>


        {/* <div className="contactcard2">
          <img className="pb-2" src={loc} alt="" />
          <div className="font-weight-bold pb-3">
            <Trans i18nKey="description.part85">Lantern Capital Partners</Trans>
          </div>
          <div>
            <Trans i18nKey="description.part88">
              Hang Seng Bank Tower
              <br /> 1000 Lujiazui Ring Road <br /> Shanghai, 200120 <br /> +86
              138 1759 9757
            </Trans>
          </div>


          <br />
          <a className="btncon" href="tel:+8613817599757">
            <Trans i18nKey="description.part87">Call us</Trans>
          </a>


        </div> */}
      </div>
    </div>
  )
}

export default Contactus
