import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import heroteam from './homepage/crossborder5.jpg'

function Ourteam() {
  const { t, i18n } = useTranslation()

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }
  return (
    <div className="investment">
      <div className="teamhero">
        {' '}
        <img src={heroteam} alt="hero-team" />
      </div>
      <div className="crossmain">
        <div className="investmain">
          <div className=" h3 font-weight-bold pb-20">
            <Trans i18nKey="description.part102">Our Team</Trans>
          </div>
        </div>

        <div className="cards">
          <div className="card1 ">
            <div className="cardteam">
              <div className="upper">
                <div>
                  <Trans i18nKey="description.part50">Bruce Quilliam</Trans>
                </div>
                <div>
                  <Trans i18nKey="description.part51">MANAGING PARTNER</Trans>
                </div>
                <a href="mailto:bruceq@lanterncapitalpartners.com">
                  <div className="email-text">
                    bruceq@lanterncapitalpartners.com
                  </div>
                </a>
              </div>
              <div className="font-weight-bold pt-3">
                <Trans i18nKey="description.part53">Experience:</Trans>
              </div>
              <div>
                <ul className="pt-2 h6">
                  {/* <li className="ml-4">
                    <Trans i18nKey="description.part54">
                      Executive Vice President, Eagle Private Equity
                    </Trans>
                  </li> */}
                  <li className="ml-4">
                    <Trans i18nKey="description.part55">
                      Partner, North Sea Group, LLC
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part56">
                      Vice President, Agilic Capital, LLC
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part57">
                     Director of Global Business Development, Perretta Graphics Corp.
                    </Trans>
                  </li>
                </ul>
              </div>
              <br />
            
              <div className="font-weight-bold pt-3">
                <Trans i18nKey="description.part58">Education:</Trans>
              </div>
              <div>
                <ul className="pt-2 h6">
                  <li className="ml-4">
                    <Trans i18nKey="description.part59">
                      MBA, Finance, Fordham University, Gabelli School of
                      Business
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part60">
                      BBA, International Marketing, Pace University, Lubin
                      School of Business
                    </Trans>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card1">
            <div className="cardteam">
              <div className="upper">
                <div>Jennifer Yao</div>
                <div>
                  <Trans i18nKey="description.part61">
                    <Trans i18nKey="description.part53">Associate</Trans>
                  </Trans>
                </div>
                <a href="mailto:victorh@lanterncapitalpartners.com">
                  {' '}
                  <div className="email-text">
                    jennifery@lanterncapitalpartners.com
                  </div>{' '}
                </a>
              </div>
              <div className="font-weight-bold pt-3">
                {' '}
                <Trans i18nKey="description.part53">Experience:</Trans>
              </div>
              <div>
                <ul className="pt-2 h6">
                  <li className="ml-4">
                    <Trans i18nKey="description.part62">
                      Risk Management Analyst, Hyde Park Investment Service
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part63">
                      Investment Banking Analyst, CICC
                    </Trans>
                  </li>
                  {/* <li className="ml-4">
                    <Trans i18nKey="description.part64">
                      Vice President, Standard Chartered Bank
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part65">
                      Associate, Citigroup
                    </Trans>
                  </li> */}
                </ul>
              </div>
              <div className="font-weight-bold pt-3" >
                <br />
                <Trans i18nKey="description.part58">Education:</Trans>
              </div>
              <div>
                <ul className="pt-2 h6">
                  <li className="ml-4">
                    <Trans i18nKey="description.part66">
                      MIEF, Johns Hopkins University-SAIS
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part1066">
                      BS in Economics, Double minor in Statistics and Analytics, and Data Science, UNC Chapel Hill
                    </Trans>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="cards cards2">
          <div className="card1">
            <div className="cardteam">
              <div className="upper">
                <div>David Overbeeke</div>
                <div>
                  <Trans i18nKey="description.part67">SENIOR ADVISOR</Trans>
                </div>
                {/* <div>davido@lanterncapitalpartners.com</div> */}
              </div>
              <div className="font-weight-bold pt-3">
                <Trans i18nKey="description.part53">Experience:</Trans>
              </div>
              <div>
                <ul className="pt-2 h6">
                  <li className="ml-4">
                    <Trans i18nKey="description.part68">
                      President & CEO, Brake Parts Inc.
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part69">
                      President, Affinia Group, LLC
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part70">
                      Operating Advisor, Oak Hill Partners III
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part71">
                      EVP & CIO, Digital Media, NBC Universal, and GE Officer
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part72">
                      CFO, Engine Services, General Electric
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part73">
                      CFO & COO, Engine Services – Wales Operation, General
                      Electric
                    </Trans>
                  </li>
                </ul>
              </div>

              <br />
              <div className="font-weight-bold pt-3" >
                <Trans i18nKey="description.part58">Education:</Trans>
              </div>
              <div >
                <ul className="pt-2 h6">
                  <li className="ml-4">
                    <Trans i18nKey="description.part74">
                      BSc, Mechanical Engineering & Management Sciences,
                      University of Waterloo
                    </Trans>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card1">
            <div className="cardteam">
              <div className="upper">
                <div>Roger Solheim</div>
                <div>
                  <Trans i18nKey="description.part1076">OPERATING ADVISOR</Trans>
                </div>
                <div>rogers@lanterncapitalpartners.com</div>
              </div>
              <div className="font-weight-bold pt-3">
                <Trans i18nKey="description.part53">Experience:</Trans>
              </div>
              <div>
                <ul className="pt-2 h6">
                  <li className="ml-4">
                    <Trans i18nKey="description.part1077">
                      General Manager, Vigor & Sage BV
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part1078">
                      General Manager, Impro X{' '}
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part1079">
                      President & CEO, TQM Center Norway / TQM Center Shanghai
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part1080">
                      Organization Director, Ramirent
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part1081">
                      Global Quality Director, Hydro Aluminum SIFA
                    </Trans>
                  </li>
                </ul>
              </div>
              <br/>
             
         
              <div className="font-weight-bold pt-3">
              
                <Trans i18nKey="description.part58">Education:</Trans>
              </div>
              <div>
                <ul className="pt-2 h6">
                  <li className="ml-4">
                    <Trans i18nKey="description.part1082">
                      BS, Social Sciences & IT, University of Oslo
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part1083">
                       Lean Processes and TQM Certification, Monterrey Institute of Technology, Juran Institute, DNV, MIT
                    </Trans>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="cards cards2">
         


          <div className="card1">
            <div className="cardteam">
              <div className="upper">
                <div>Nichole Mo</div>
                <div>
                  <Trans i18nKey="description.part76">Analyst</Trans>
                </div>
                <div>nicholem@lanterncapitalpartners.com</div>
              </div>
              <div className="font-weight-bold pt-3">
                <Trans i18nKey="description.part53">Experience:</Trans>
              </div>
              <div>
                <ul className="pt-2 h6">
                  <li className="ml-4">
                    <Trans i18nKey="description.part77">
                    Actuarial Assistant, Sun Life Hong Kong Ltd
                    </Trans>
                  </li>
                  <li className="ml-4">
                    <Trans i18nKey="description.part78">
                    Research Assistant, Shanghai Hesion Corporate Consulting Limited
                    </Trans>
                  </li>
                 
                 
                 
                </ul>
              </div>
              <br />
             

              <div className="font-weight-bold pt-3">

                <Trans i18nKey="description.part58">Education:</Trans>
              </div>
              <div>
                <ul className="pt-2 h6">
                  <li className="ml-4">
                    <Trans i18nKey="description.part82">
                    Bsc, Mathematics, The Hong Kong University of Science and Technology
                    </Trans>
                  </li>
                 
                </ul>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  )
}

export default Ourteam
