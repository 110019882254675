import React from 'react'
import './css/style.css'
import { Trans, useTranslation } from 'react-i18next'
import newyork from './homepage/homepage-mobile5.jpg'
// import hongkong from './homepage/hongkong3.jpg'
// import sanfrancisco from './homepage/sanfrancisco3.jpg'
import homepage from './homepage/homepage5.jpg'

import { Link } from 'react-router-dom'

function Homepage() {
  const { t, i18n } = useTranslation()

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }

  return (
    <>
      <div
        id="carouselExampleSlidesOnly"
        class="carousel slide"
        data-ride="carousel"
        data-interval="5000"
      >
        <div className="herotxt">
          <Trans i18nKey="description.part1">
            Creating Value Through Growth and Operational Performance
          </Trans>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              class="hero-image d-block w-100 newyork"
              src={homepage}
              alt="First slide"
            />
          </div>
          {/* <div class="carousel-item">
            <img
              class="hero-image d-block w-100 image_height"
              src={hongkong}
              alt="Second slide"
            />
          </div> */}
          {/* <div class="carousel-item">
            <img
              class="hero-image d-block w-100 image_height"
              src={sanfrancisco}
              alt="Third slide"
            />
          </div> */}
        </div>

        <Link to="/about-us">
          <div className="herobtn">
            <Trans i18nKey="description.part2">
              <p>See What We Do</p>
            </Trans>
          </div>{' '}
        </Link>
      </div>
    </>
  )
}

export default Homepage
