import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import crossborder from './homepage/team5.jpg'

function Crossborder() {
  const { t, i18n } = useTranslation()

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }
  return (
    <div className="investment">
      <div className="crosshero">
        <img src={crossborder} alt="hero-contact" />
      </div>
      <div className="investmain">
        <div className=" h3 font-weight-bold">
          <Trans i18nKey="description.part41">Cross-Border M&A Solutions</Trans>
        </div>
        <div className="section-description h6 pt-2">
          <Trans i18nKey="description.part42">
            In addition to our direct investing activities, we can help your
            firm pursue and execute offshore mergers and acquisitions. Our
            network of investment banking and corporate relationships in both
            United States and China provides access to middle-market deal flow
            ideally suited to your firm’s M&A strategy. A sample of the services
            we can provide include:
          </Trans>
        </div>
        <div className="crosscards">
          <div className="cardcross">
            <div className="cardborder">
              <p className="boxtext">
                <Trans i18nKey="description.part43">
                  {' '}
                  Identify and approach appropriate acquisition targets
                </Trans>
              </p>
            </div>
            <div className="cardborder">
              <p className="boxtext">
                <Trans i18nKey="description.part44">
                  {' '}
                  Serve as an intermediary for both buy-side and sell-side
                  transactions
                </Trans>
              </p>
            </div>
            <div className="cardborder">
              <p className="boxtext">
                <Trans i18nKey="description.part45">
                  {' '}
                  Build robust financial models to determine an appropriate
                  valuation range for the target acquisition
                </Trans>
              </p>
            </div>
          </div>
          <div className="cardcross">
            <div className="cardborder">
              <p className="boxtext">
                <Trans i18nKey="description.part46">
                  {' '}
                  Assist or take the lead in carrying out target due diligence
                </Trans>
              </p>
            </div>
            <div className="cardborder">
              <p className="boxtext">
                <Trans i18nKey="description.part47">
                  {' '}
                  Identify and recruit an executive management team where
                  applicable
                </Trans>
              </p>
            </div>
            <div className="cardborder">
              <p className="boxtext">
                <Trans i18nKey="description.part48">
                  {' '}
                  Post-acquisition support for ownership transition and
                  integration
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Crossborder
