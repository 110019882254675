import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import investmenthero from './homepage/investmentcriteria5.jpg'

function Investmentcriteria() {
  const { t, i18n } = useTranslation()

  const handleClick = () => {
    window.open(
      'https://drive.google.com/file/d/1U2IgB0wRhP9ZeOY3UuSy8r6R12WYKxTz/view?usp=sharing',
    )
  }

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }
  return (
    <div className="investment">
      <div className="heroinvest">
        {' '}
        <img src={investmenthero} alt="hero-team" />
      </div>
      <div className="criteria">
        <div className="investmain">
          <div className=" h3 font-weight-bold">
            <Trans i18nKey="description.part26">Investment Criteria</Trans>
          </div>
        </div>

        <div className="investsection ">
          <div className="heading">
            <Trans i18nKey="description.part27">PROFILE</Trans>
          </div>
          <div className="para">
            <Trans i18nKey="description.part28">
              Businesses operating in industry sectors characterized by highly
              engineered, non-commodity products or critical services.
            </Trans>
          </div>
        </div>
        {/* <hr className="investhr" /> */}
        <div className="investsection">
          <div className="heading">
            <Trans i18nKey="description.part29">SIZE</Trans>
          </div>
          <div className="para">
            <ul>
              <li>
                <Trans i18nKey="description.part30">
                  $20 to $100 million in revenue
                </Trans>
              </li>
              <li>
                <Trans i18nKey="description.part31">
                  $5 to $15 million in EBITDA
                </Trans>
              </li>
            </ul>
          </div>
        </div>
        {/* <hr className="investhr" /> */}
        <div className="investsection">
          <div className="heading">
            <Trans i18nKey="description.part32">MANAGEMENT</Trans>
          </div>
          <div className="para">
            <Trans i18nKey="description.part33">
              We prefer to work with existing management teams but are not
              opposed to investing in situations where active owners are seeking
              a complete transition out of the business. Our viewpoint is that
              we support and augment company management; therefore, we do not
              involve ourselves in day-to-day operations.{' '}
            </Trans>
          </div>
        </div>
        {/* <hr className="investhr" /> */}
        <div className="investsection">
          <div className="heading">
            <Trans i18nKey="description.part34">SELLERS</Trans>
          </div>
          <div className="para">
            <Trans i18nKey="description.part35">
              Although we prefer to be the first institutional investor when
              considering a given acquisition, private equity group exits and
              corporate carve-outs are welcome provided there is a clear path to
              further value creation.
            </Trans>
          </div>
        </div>
        {/* <hr className="investhr" /> */}
        <div className="investsection">
          <div className="heading">
            <Trans i18nKey="description.part36">
              ADD-ON <br /> ACQUISITIONS
            </Trans>
          </div>
          <div className="para">
            <Trans i18nKey="description.part37">
              We have no minimum EBITDA for add-on acquisitions and add-ons may
              be located outside of North America.{' '}
            </Trans>
          </div>
        </div>
        {/* <hr className="investhr" /> */}
        <div className="investsection">
          <div className="heading">
            <Trans i18nKey="description.part38">
              SPECIAL <br /> SITUATIONS
            </Trans>
          </div>
          <div className="para">
            <Trans i18nKey="description.part39">
              Turnaround opportunities are considered on a case-by-case basis,
              but the business must operate in a stable industry with favorable
              future prospects.
            </Trans>
          </div>
        </div>
        {/* <hr className="investhr" /> */}
        <div className="invest-button">
          <a className="brochure-bottom" onClick={handleClick}>
            <Trans i18nKey="description.part999">Download Brochure </Trans>
          </a>{' '}
        </div>
      </div>
    </div>
  )
}

export default Investmentcriteria
