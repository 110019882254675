import React from 'react'
import Homepage from './homepage'
import Aboutus from './aboutus'
import Contactus from './contactus'
import Investmentcriteria from './investmentciteria'
import Ourteam from './ourteam'
import Crossborder from './crossborder'
import './css/style.css'
import logo2 from './homepage/logo2.svg'
import usa from './homepage/Group 68.png'
import china from './homepage/download.jpg'
import { Trans, useTranslation } from 'react-i18next'
import { BrowserRouter as BrowserRouter, Switch, Route, Link } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import { HashRouter } from 'react-router-dom'
function Routerfile({ toggle }) {
  const { t, i18n } = useTranslation()

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }

  const toggleHome = () => {
    scroll.scrollToTop()
  }

  const handleClick = () => {
    window.open(
      'https://drive.google.com/file/d/1U2IgB0wRhP9ZeOY3UuSy8r6R12WYKxTz/view?usp=sharing',
    )
  }

  return (
    <>
      <HashRouter>
        <div className="fixednav">
          <nav className="navbar navbar-expand-xl navbar-light ">
            <a className="navbar-brand" href="/">
              <img
                className="mainlogo"
                src={logo2}
                alt="logo"
                onClick={toggleHome}
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="menu-brochure hamburger collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className=" navbar-nav ml-auto">
                <li
                  className="navmenu nav-item"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-label="Toggle navigation"
                >
                  <Link
                    className="navlist nav-link"
                    to="/about-us"
                    onClick={toggleHome}
                  >
                    <Trans i18nKey="description.part100">About Us</Trans>
                  </Link>
                </li>
                <li className="navmenu nav-item"
                              data-toggle="collapse"
                              data-target="#navbarSupportedContent"
                              aria-controls="navbarSupportedContent"
                              aria-label="Toggle navigation">
                  <Link
                    className="navlist nav-link"
                    to="/investment-criteria"
                    onClick={toggleHome}
                  >
                    <Trans i18nKey="description.part6">
                      Investment Criteria
                    </Trans>
                  </Link>
                </li>
                <li className="navmenu nav-item"
                              data-toggle="collapse"
                              data-target="#navbarSupportedContent"
                              aria-controls="navbarSupportedContent"
                              aria-label="Toggle navigation">
                  <Link
                    className="navlist nav-link"
                    to="/cross-border"
                    onClick={toggleHome}
                  >
                    <Trans i18nKey="description.part7">Cross-Border M&A</Trans>
                  </Link>
                </li>
                <li className="navmenu nav-item"
                              data-toggle="collapse"
                              data-target="#navbarSupportedContent"
                              aria-controls="navbarSupportedContent"
                              aria-label="Toggle navigation">
                  <Link
                    className="navlist nav-link"
                    to="/our-team"
                    onClick={toggleHome}
                  >
                    <Trans i18nKey="description.part8">Team</Trans>
                  </Link>
                </li>
                <li className="navmenu nav-item"
                              data-toggle="collapse"
                              data-target="#navbarSupportedContent"
                              aria-controls="navbarSupportedContent"
                              aria-label="Toggle navigation">
                  <Link
                    className="navlist nav-link"
                    to="/contact-us"
                    onClick={toggleHome}
                  >
                    <Trans i18nKey="description.part9">contact</Trans>
                  </Link>
                </li>
              </ul>
              <button onClick={() => changeLanguage('en')}>
                {' '}
                <img className="trans" src={usa} alt="" />
              </button>
              <button onClick={() => changeLanguage('de')}>
                {' '}
                <img className="trans" src={china} alt="" />
              </button>
            </div>
          </nav>
        </div>

        <div>
          <Switch>
            <Route exact path="/" component={Homepage} >
            </Route>
            <Route exact path="/about-us" component={Aboutus}>
              {/* <Aboutus /> */}
            </Route>
            <Route exact path="/investment-criteria" component={Investmentcriteria}>
              {/* <Investmentcriteria /> */}
            </Route>
            <Route exact path="/cross-border" component={Crossborder}>
              {/* <Crossborder /> */}
            </Route>
            <Route exact path="/our-team" component={Ourteam}>
              {/* <Ourteam /> */}
            </Route>
            <Route exact path="/contact-us" component={Contactus}>
              {/* <Contactus /> */}
            </Route>
          </Switch>
          <div className="footer">
            <nav>
              <ul className="navbar1">
                <li>
                  <Link className="navlist1" to="/about-us" onClick={toggleHome}>
                    <Trans i18nKey="description.part100">About Us</Trans>
                  </Link>
                </li>
                <li>
                  <Link className="navlist1" to="/investment-criteria" onClick={toggleHome}>
                    <Trans i18nKey="description.part6">
                      Investment Criteria
                    </Trans>
                  </Link>
                </li>
                <li>
                  <Link className="navlist1" to="/cross-border" onClick={toggleHome}>
                    <Trans i18nKey="description.part7">Cross-Border M&A</Trans>
                  </Link>
                </li>

                <li>
                  <Link className="navlist1" to="/our-team" onClick={toggleHome}>
                    <Trans i18nKey="description.part8">Team</Trans>
                  </Link>
                </li>
                <li>
                  <Link className="navlist1" to="/contact-us" onClick={toggleHome}>
                    <Trans i18nKey="description.part9">Contact</Trans>
                  </Link>
                </li>
                <li>
                  <Link className="navlist1" onClick={handleClick}>
                    <Trans i18nKey="description.part103">
                      Download Brochure
                    </Trans>
                  </Link>
                </li>
              </ul>
            </nav>

            <hr />
            <div className="rights">
              <Trans i18nKey="description.part11">
                Copyright &#169; 2021 Lantern Capital Partners. All Rights
                Reserved
              </Trans>
            </div>
          </div>
        </div>
      </HashRouter>
  
    </>
  )
}

export default Routerfile
