import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import icon from './about/icn settings .icn-md.png'
import FiveG from '../components/homepage/5g.svg'
import aerospace from '../components/homepage/aerospace.svg'
import automotive from '../components/homepage/automotive.png'
import critical from '../components/homepage/critical.svg'
import energy from '../components/homepage/energy.svg'
import industrial from '../components/homepage/industrial.svg'
import information from '../components/homepage/information.svg'
import manufacturing from '../components/homepage/manufacturing.svg'
import medical from '../components/homepage/medical.svg'
import saas from '../components/homepage/saas.svg'
import abouthero from './homepage/about5.jpg'

function Aboutus() {
  const { t, i18n } = useTranslation()

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }
  return (
    <div className="investment">
      <div className="heroabout">
        {' '}
        <img src={abouthero} alt="hero-contact" />
      </div>

      <div className="investmain">
        <div className=" h3 font-weight-bold">
          <Trans i18nKey="description.part100">About Us</Trans>
        </div>

        <div className="aboutmain section-description">
          <Trans i18nKey="description.part12">
            Lantern Capital Partners works with its investors and partners to
            identify and acquire attractive businesses operating in engineering
            intensive industry verticals. Our associates have extensive
            international experience in business development, operations
            management, strategy, lean processes, M&A and organizational change.
            Our value creation strategies may include prudent cost and expense
            management, product development, international expansion, and
            buy-and-build.
          </Trans>
        </div>
        <div className="maincon">
          <div className="points">
            <div className="interests font-weight-bold h3">
              <Trans i18nKey="description.part13">
                Areas of particular interest include:
              </Trans>
            </div>
            <div className="list">
              <div className="sect1">
                <div className="icon11">
                  <img className="p-2 mr-3 icons" src={aerospace} alt="" />
                  <Trans i18nKey="description.part14">Aerospace</Trans>
                </div>
                <div className="icon11">
                  <img className="p-2 mr-3 icons" src={automotive} alt="" />
                  <Trans i18nKey="description.part15">Automotive</Trans>
                </div>
                <div className="icon11">
                  <img className="p-2 mr-3 icons" src={manufacturing} alt="" />
                  <Trans i18nKey="description.part16">
                    Advanced Manufacturing
                  </Trans>
                </div>
                <div className="icon11">
                  <img className="icons p-2 mr-3" src={industrial} alt="" />
                  <Trans i18nKey="description.part17">
                    Industrial Technology
                  </Trans>
                </div>
                <div className="icon11">
                  <img className="p-2 mr-3 icons" src={information} alt="" />
                  <Trans i18nKey="description.part18">
                    Information Technology
                  </Trans>
                </div>
              </div>
              <div className="sect2">
                <div className="icon11">
                  <img className="p-2 mr-3 icons" src={saas} alt="" />
                  <Trans i18nKey="description.part20">SaaS</Trans>
                </div>
                <div className="icon11">
                  <img className="p-2 mr-3 icons" src={medical} alt="" />
                  <Trans i18nKey="description.part21">
                    Packaging
                  </Trans>
                </div>
                <div className="icon11">
                  <img className="p-2 mr-3 icons" src={FiveG} alt="" />
                  <Trans i18nKey="description.part22">5G / Wireless</Trans>
                </div>
                <div className="icon11">
                  <img className="p-2 mr-3 icons" src={energy} alt="" />
                  <Trans i18nKey="description.part24">Energy Storage</Trans>
                </div>

                <div className="icon11">
                  <img className="p-2 mr-3 icons" src={critical} alt="" />
                  <Trans i18nKey="description.part25">Critical Services</Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Aboutus
