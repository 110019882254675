import React from 'react'
import Routerfile from './components/router';
import { HashRouter } from 'react-router-dom'

function App() {
  return (
    <>
    <HashRouter>
      <Routerfile />
      </HashRouter>
    </>
  );
}

export default App;
